import Vue from 'vue'
import { mapState } from 'vuex'
import Wallet from '@/models-ts/Wallet'
import WalletConnectV2 from '@/partials/WalletConnectV2/WalletConnectV2.vue'
import { WALLET_GROUP_CLOUD, WalletGroup } from '@/constants/blockchain'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  components: {
    WalletConnectV2,
  },
  props: {
    value: Object, // Wallet,
    disabled: Boolean,
    errorMsg: String,
    useDefault: Boolean,
  },
  data () {
    return {
      WALLET_GROUP_CLOUD,
    }
  },
  computed: {
    ...mapState<RootState>('user', {
      wallets: (state: any) => state.wallets,
      defaultWallet: (state: any) => state.wallet,
    }),
    isWalletConnect () {
      return this.value?.group === WalletGroup.WalletConnect
    },
  },
  created () {
    if (this.useDefault) {
      this.onWalletChange(this.defaultWallet)
    }
  },
  methods: {
    onWalletChange (wallet: Wallet | null) {
      if (wallet) {
        this.$emit('input', wallet)
      }
    },
    getWalletImage (wallet: Wallet) {
      switch (wallet.group) {
        case WalletGroup.Metamask:
          return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect:
          return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink:
          return '/static/images/wallets/tron-wallet.png'
        default:
          return '/static/images/wallets/cloud-wallet.svg'
      }
    },
  }
})
