import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { RootState } from '@/store'
import responseMixin from '@/mixins/responseMixin'

export default Vue.extend({
  mixins: [responseMixin],
  computed: {
    ...mapState<RootState>({
      rates: (state: RootState) => state.rates.rates.value,
      ratesLoaded: (state: RootState) => state.rates.rates.isLoaded,
    }),
  },
  async created (this: any) {
    try {
      await this.getRates()
    } catch (e) {
      console.error('Load rates error:', e)
    }
  },
  methods: {
    ...mapActions({
      getRates: 'rates/getRates',
    })
  },
})
